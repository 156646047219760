import * as React from 'react';

import { Col, Container, Row } from 'react-bootstrap';

import { Layout } from '../components';

const IndexPage = () => {
  return (
    <>
      <Layout.NavMenu solidColor notFixed />
      <Container>
        <Row>
          <Col>
            <h1 style={{ fontSize: '3.5rem' }} className="mb-5">
              Frequently Asked Questions
            </h1>
            <h2 className="fs-4 mb-2">Q: If I protest my property taxes, does that mean my house is worth less?</h2>
            <div className="mb-3">
              A: No, no, no. The market value of a home is determined by recent sale in an area, not by the appraisal district’s value. If you plan to sell your
              house, it may actually be attractive to a buyer if the taxes on your home are less. That means less out of pocket for them.
            </div>
            <div className="fst-italic mb-5">
              Texas Tax Protest is available year round to answer your property tax questions and address any concerns you may have about the protest process.
              When you call you will always have the opportunity to speak with one of our agents. No recordings, just real people.
            </div>

            <h2 className="fs-4 mb-2">Q: Appealing my property taxes seems overwhelming. Where do I start?</h2>
            <div className="mb-3">A: Each appraisal district has their own process and guidelines to help homeowners protest.</div>
            <div className="fst-italic mb-5">
              Texas Tax Protest takes all the guess work and uncertainty out of the protest process. Start by signing up with us. The process only requires
              three forms, which we fill out for you. All you need to do is review and sign. It’s really that easy!
            </div>

            <h2 className="fs-4 mb-2">Q: Is it risky to appeal? Can my assessed value be raised if I protest?</h2>
            <div className="mb-3">
              A: The short answer is not really. At an informal hearing, the central appraisal district is not permitted to raise your assessed value so there
              is no risk to you. The majority of tax protests are settled at this informal hearing.
            </div>
            <div className="mb-3">
              In an instance where a reduction cannot be negotiated at an informal hearing, you must present your case to the Appraisal Review Board. Rarely are
              assessed values raised at the Appraisal Review Board hearing. In fact, property taxes are only raised about once in every 10,000 Appraisal Review
              Board hearings. That is less than 1% of the time!
            </div>
            <div className="mb-3">
              While there’s little risk your property taxes will increase, we do not advise you protest yourself. In 2010, of those who filed their own protest,
              only 22% achieved a value reduction. Of those who were represented, 79% achieved a reduction. It is to your advantage to seek out representation
              so you have the best chance to achieve a property tax reduction. Texas Tax Protest can help!
            </div>

            <div className="fst-italic mb-5">
              Texas Tax Protest makes every effort to negotiate a reduction for you at an informal hearing. However, if we cannot obtain a reduction at the
              informal hearing, we will contact you and discuss our strategy for achieving a reduction at the appraisal review board hearing. That way you have
              the peace of mind that we are working to reduce your assessed value to the best of our ability.
            </div>

            <h2 className="fs-4 mb-2">Q: If my property is assessed for less than market value, can I still receive a reduction in my assessed value?</h2>
            <div className="mb-3">
              A: Yes you can by using the unequal appraisal method. The appraisal districts permit you to compare the assessed value of your property to the
              assessed value of similar properties. If the similar properties are also assessed below market value and your property is appraised higher than
              the others, you may be eligible for a reduction.
            </div>

            <div className="fst-italic mb-5">Texas Tax Protest uses the unequal appraisal method in many cases to reduce homeowner’s property taxes!</div>

            <h2 className="fs-4 mb-2">Q: When is the deadline to protest my property value?</h2>
            <div className="mb-3">A: In Texas, a hearing request must be submitted my May 15th of the tax year.</div>
            <div className="fst-italic mb-5">
              Texas Tax Protest offers a discount for homeowners that sign up for our service prior to May 1st. Sign up early!
            </div>

            <h2 className="fs-4 mb-2">Q: When do the new tax rates come out?</h2>
            <div className="mb-3">A: Tax rates are typically set in November of the current tax year.</div>

            <div className="fst-italic mb-5">
              Texas Tax Protest has no control over tax rates, but we can offer our expertise in helping you reduce you home’s assessed value so you pay less in
              property taxes overall.
            </div>

            <h2 className="fs-4 mb-2">Q: Will the appraisal district inspect my house if I protest?</h2>
            <div className="mb-3">A: Never.</div>

            <div className="fst-italic mb-5">
              Texas Tax Protest never needs to inspect your home when representing you at a protest. In fact, most clients sign up for our service by phone and
              we never go in your home. We do advise that you provide us with pictures of the inside of you home if there is damage or areas of the home are in
              poor condition. However, the majority of the protest we settle are normal homes in good working order and we rely on comparable properties to
              reduce the assessed value, not on pictures.
            </div>
          </Col>
        </Row>
      </Container>
      <Layout.Footer />
    </>
  );
};

export default IndexPage;

export const Head = () => <Layout.PageHead subtitle="FAQs" />;
